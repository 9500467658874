export const USER_FIELDS = {
  USER_FULL_NAME: 'userFullName',
  EMAIL: 'email',
  PHONE: 'phone',
  PASSWORD: 'password',
  USER_ROLE: 'userRole',
  USER_TYPE: 'userType',
  CPF: 'cpf',
  NAME: 'name',
  ROLE: 'role',
}

export const CONTACT_FIELDS = {
  CLIENT_TYPE: 'clientType',
  CONTACT_CPF_CNPJ: 'contactCpfCnpj',
  CONTACT_NAME: 'contactName',
  CONTACT_NUMBER: 'contactNumber',
  FIRST_CONTACT_SECONDARY: 'firstContactSecondary',
  SECOND_CONTACT_SECONDARY: 'secondContactSecondary',
  CONTACT_EMAIL: 'contactEmail',
  FIRST_SUGGESTION_DATE: 'firstSuggestionDate',
  FIRST_START_TIME: 'firstStartTime',
  FIRST_END_TIME: 'firstEndTime',
  SECOND_SUGGESTION_DATE: 'secondSuggestionDate',
  SECOND_END_TIME: 'secondEndTime',
  SECOND_START_TIME: 'secondStartTime',
  THIRD_SUGGESTION_DATE: 'thirdSuggestionDate',
  THIRD_START_TIME: 'thirdStartTime',
  THIRD_END_TIME: 'thirdEndTime',
}

export const USER_ROLES = {
  CS_APPROVER: 'cs_approver',
  CS_REFERA: 'cs_refera',
  REFERA: 'refera',
  CS_FINANCE: 'cs_finance',
  CS_MANAGER: 'cs_manager',
  TRADESMAN: 'tradesman',
  LESSEE: 'lessee',
  INTERMEDIARY: 'intermediary',
  SAAS: 'saas',
}

export const USER_ROLES_LABEL = {
  [USER_ROLES.CS_APPROVER]: 'Intermediária',
  [USER_ROLES.CS_REFERA]: 'Refera',
  [USER_ROLES.CS_FINANCE]: 'Financeiro',
  [USER_ROLES.CS_MANAGER]: 'Intermediária',
  [USER_ROLES.TRADESMAN]: 'Prestador',
  [USER_ROLES.LESSEE]: 'Inquilino',
}

export const USER_FIELDS_LABEL = {
  [USER_FIELDS.USER_FULL_NAME]: 'Nome completo',
  [USER_FIELDS.NAME]: 'Nome completo',
  [USER_FIELDS.EMAIL]: 'Email',
  [USER_FIELDS.PHONE]: 'Telefone',
  [USER_FIELDS.PASSWORD]: 'Senha',
  [USER_FIELDS.USER_ROLE]: 'Qual o nível de acesso?',
  [USER_FIELDS.USER_TYPE]: 'Tipo de cliente',
  [USER_FIELDS.CPF]: 'CPF',
}

export const CLIENT_TYPE = {
  STAFF: 'agency_worker_client',
  TENANT: 'lessee_client',
  OWNER: 'property_owner_client',
}

export const CLIENT_TYPE_LABEL = {
  [CLIENT_TYPE.STAFF]: 'Funcionário da imobiliária',
  [CLIENT_TYPE.TENANT]: 'Inquilino',
  [CLIENT_TYPE.OWNER]: 'Proprietário',
}

export const CLIENT_TYPE_CONVERT = {
  [CLIENT_TYPE_LABEL[CLIENT_TYPE.STAFF]]: CLIENT_TYPE.STAFF,
  [CLIENT_TYPE_LABEL[CLIENT_TYPE.TENANT]]: CLIENT_TYPE.TENANT,
  [CLIENT_TYPE_LABEL[CLIENT_TYPE.OWNER]]: CLIENT_TYPE.OWNER,
}
